<template>
  <v-row align="center" justify="center">
    <v-col class="col-12">
      <DocumentUploader :create-contract="true" :contract-id="activeContractId" :workspace-id="activeWorkspaceId" />
    </v-col>

    <v-col v-if="panelLoad && !isAttachment" class="col-12">
      <div
        class="storage__item storage__item--bottom storage__item--top-layout"
        style="cursor: pointer"
        @click="onUploadFile"
      >
        <div class="storage__item-wrap">
          <div class="storage__ico">
            <IcoDropbox />
          </div>

          <div class="storage__name">Dropbox</div>
        </div>
      </div>
    </v-col>

    <v-col v-if="panelLoad && !isAttachment" class="col-12">
      <div
        class="storage__item storage__item--bottom storage__item--left storage__item--bottom-layout"
        style="cursor: pointer"
        @click="onUploadFile"
      >
        <div class="storage__item-wrap">
          <div class="storage__ico">
            <IcoGoogleDrive />
          </div>

          <div class="storage__name">G-Drive</div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import IcoCreate from '@/components/svg/IcoCreate.vue'
import IcoDropbox from '@/components/svg/IcoDropbox.vue'
import IcoGoogleDrive from '@/components/svg/IcoGoogleDrive.vue'
import DocumentUploader from '@/features/contract/components/DocumentUploader'

export default {
  name: 'Storage',
  components: {
    IcoCreate,
    IcoDropbox,
    IcoGoogleDrive,
    DocumentUploader,
  },
  props: {
    panelLoad: {
      type: Boolean,
      default: false,
    },
    isAttachment: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      workspaceById: 'workspaceById',
    }),
    activeContractId() {
      return this.$route.params.contract_id
    },
    activeWorkspaceId() {
      return this.$route.params.workspace_id || localStorage.getItem('workspace_id')
    },
    activeWorkspace() {
      return this.workspaceById(this.activeWorkspaceId)
    },
  },
  methods: {
    onUploadFile() {
      this.$emit('docClick')
    },
  },
}
</script>

<style lang="scss" scoped>
.storage__item {
  position: relative;
  box-sizing: border-box;
  width: 50%;
  float: left;
  height: 90px;
  line-height: 90px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  @include clearfix;

  @include md {
    height: 60px;
    line-height: 60px;
  }
}

.storage__item:first-child {
  width: 100%;
}

.storage__item.disabled:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.4);
}

.storage__item--bottom {
  border-bottom: 1px solid #d4dff0;
  @include md {
    border: none;
  }
}

.storage__item--left {
  border-left: 1px solid #d4dff0;
  @include md {
    border: none;
  }
}

.storage__ico {
  float: left;
  display: inline-block;
  padding-left: 42px;
  line-height: 90px;

  svg {
    vertical-align: middle;
  }
  @include md {
    line-height: 60px;
    padding-left: 20px;
  }
}

.storage__name {
  float: left;
  display: inline-block;
  padding-left: 15px;
  font-size: 19px;
  letter-spacing: -0.04px;
  color: #5e239e;
  @include md {
    font-weight: 300;
    font-size: 15px;
  }
}

.storage__desc {
  position: absolute;
  top: 20px;
  font-size: 12px;
}

.storage__item--top-layout {
  @include md {
    width: 100%;
    border: 1px solid #edf4fe;
    margin-bottom: 10px;
    background: white;
    border-radius: 6px;
  }
}
.storage__item--bottom-layout {
  @include md {
    width: 100%;
    border: 1px solid #edf4fe;
    margin-bottom: 10px;
    background: white;
    border-radius: 6px;
  }
}

.storage__item--right-layout {
  @include md {
    width: 50%;
    margin-bottom: 10px;
    padding-left: 5px;
    right: 0;
    top: 70px;
  }
}

.storage__item--left-layout {
  @include md {
    width: 50%;
    margin-bottom: 10px;
    padding-right: 5px;
    // position: absolute;
    left: 0;
    top: 70px;
  }
}

.storage__item-wrap {
  @include md {
    border: 1px solid #edf4fe;
    background: white;
    height: 60px;
    line-height: 60px;
    border-radius: 6px;
  }
}
</style>
